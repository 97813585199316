import React from "react"
import { css } from "@emotion/core"

const BannerShapeTransferPricing = () => (
  <div
    css={css`
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @media (min-width: 576px) {
        display: none;
      }
    `}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
      css={css`
        display: block;
        position: relative;
        width: calc(100% + 46rem);
        height: 16rem;
        left: 50%;
        transform: translateX(-50%);
      `}
    >
      <path
        fill="#3f8053"
        d="M1000,4.3V0H0v4.3C0.9,23.1,126.7,99.2,500,100S1000,22.7,1000,4.3z"
      ></path>
    </svg>
  </div>
)

export default BannerShapeTransferPricing
